/* CustomScrollbar.css */

/* For Webkit browsers (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(155, 155, 155, 0.7);
  }
  
  /* For Firefox */
  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
  }
  
  /* For Edge and IE */
  .custom-scrollbar {
    -ms-overflow-style: none;
  }
  
  .custom-scrollbar::-ms-scrollbar {
    width: 8px;
  }
  
  .custom-scrollbar::-ms-scrollbar-track {
    background: transparent;
  }
  
  .custom-scrollbar::-ms-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
  }
  
  .custom-scrollbar::-ms-scrollbar-thumb:hover {
    background-color: rgba(155, 155, 155, 0.7);
  }

  /* Add these rules at the end of your CustomScrollbar.css */

.dark .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(200, 200, 200, 0.5);
}

.dark .custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(200, 200, 200, 0.7);
}

.dark .custom-scrollbar {
  scrollbar-color: rgba(200, 200, 200, 0.5) transparent;
}