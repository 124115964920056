.google-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #dadce0;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #3c4043;
    cursor: pointer;
    transition: background-color .3s, box-shadow .3s;
  }
  
  .google-login-button:hover {
    box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
  }
  
  .google-login-button:active {
    background-color: #eee;
  }
  
  .google-login-button img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }